import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    
} from '@mui/material';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import Moment from 'moment';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [leaders, setLeaders] = React.useState(null);

    Moment.locale('en');
    
    function updateLeaders() {
        fetch(`${brand.api_base_url}/leaders`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setLeaders(data);
                console.log(data);
                document.title = `Leaderboard - ${brand.name}`;
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        updateLeaders();
        
    },[authToken]);
    
    return (
        <React.Fragment>
                <Typography variant="h4">Leaderboard for Week {Moment().format("w")}</Typography>
                <Typography variant="body1">The leaderboard displays your team members with the highest GuruNow Performance Score&trade; for the current week.</Typography>
                <List sx={{ width: '100%', maxWidth: 800, m: 2, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="https://dev.gurunow.ai/media/WebAssets73.png" />
        </ListItemAvatar>
        <ListItemText
          primary="1. Sarah Lycett"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                Total Score 9.4
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="https://dev.gurunow.ai/media/WebAssets70.png" />
        </ListItemAvatar>
        <ListItemText
          primary="2. Peter Goodman"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                Total Score 8.3
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="https://dev.gurunow.ai/media/WebAssets77.png" />
        </ListItemAvatar>
        <ListItemText
          primary="3. Saul Dewalt"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                Total Score 7.5
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="https://dev.gurunow.ai/media/WebAssets71.png" />
        </ListItemAvatar>
        <ListItemText
          primary="4. Dean McFell"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                Total Score 5.2
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
        </React.Fragment>
    );
}