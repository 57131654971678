import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    
} from '@mui/material';
import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [simulations, setSimulations] = React.useState(null);
    const [selectedSimulation, setSelectedSimulation] = React.useState(null);
    const timeAgo = new TimeAgo('en-US');

    const navigate = useNavigate();
    
    function updateSimulations() {
        fetch(`${brand.api_base_url}/simulations`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setSimulations(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Past Simulations - ${user.company && user.company.name} - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateSimulations();
        
    },[authToken]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 300 },
        {
            field: 'scenario_name',
            headerName: 'Name',
            width: 300,
            valueGetter: (params) => {
                return params.row.scenario.name;
            }
        },
        {
            field: 'started_at',
            headerName: 'Started at',
            width: 200,
            type: 'dateTime',
            valueGetter: (params) => {
                return new Date(params.value);
            },
            valueFormatter: (params) => {
                return timeAgo.format(params.value);
            },
        },
        {
            field: 'score',
            headerName: 'Score',
            width: 300,
            valueGetter: (params) => {
                if(params.row.evaluation == null) {
                    return "N/A";
                }
                const score = params.row.evaluation.total_score?.value || params.row.evaluation.total_score
                const limitedScore = score ? parseFloat(score).toFixed(2) : score;
                return limitedScore + "/100";
            }
        },
      ];
    
    const handleRowClickEvent: GridEventListener<'rowClick'> = (
        params, // GridRowParams
        event, // MuiEvent<React.MouseEvent<HTMLElement>>
        details, // GridCallbackDetails
    ) => {
        navigate(`/dashboard/feedback/${params.row.id}`);
    };
    
    return (
        <React.Fragment>
            <Typography variant="h4">Past Simulations</Typography>
            {simulations && (
                <Paper sx={{ m: 3, p: 2 }}>
                        <DataGrid
                        rows={simulations}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        onRowClick={handleRowClickEvent}
                    />
                </Paper>
            )}
            
        </React.Fragment>
    );
}