import React from 'react';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    
} from '@mui/material';
import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import { LineChart } from '@mui/x-charts/LineChart';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [settings, setSettings] = React.useState(null);

    const [selectedTeam, setSelectedTeam] = React.useState('all');

    const handleChange = (event) => {
        setSelectedTeam(event.target.value);
    };
    
    function updateSettings() {
        fetch(`${brand.api_base_url}/settings`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setSettings(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Team - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateSettings();
        
    },[authToken]);
    
    return (
        <React.Fragment>
            <Typography variant="h4">Your team statistics (August 2024)</Typography>
            <FormControl variant="filled" sx={{ m: 2, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-filled-label">Team</InputLabel>
                <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedTeam}
                onChange={handleChange}
                >
                <MenuItem value="all">
                    <em>All</em>
                </MenuItem>
                <MenuItem value={10}>DBM East Coast 1</MenuItem>
                <MenuItem value={20}>DBM East Coast 2</MenuItem>
                <MenuItem value={30}>DBM West Coast</MenuItem>
                </Select>
            </FormControl>
            <Typography variant="h5">Total Score</Typography>
            <LineChart
                sx={{ m: 3 }}
                height={500}
                width={800}
                series={[
                    { data: [7.2, 7.8, 8.1, 8.3], label: 'Peter Goodman' },
                    { data: [7.0, 7.2, 7.4, 7.5], label: 'Saul Dewalt' },
                    { data: [8.9, 9.0, 9.0, 9.1], label: 'Sarah Lycett' },
                    { data: [6.5, 6.5, 5.5, 5.2], label: 'Dean McFell' },
                    ]}
                    xAxis={[{ scaleType: 'point', data: ['August 5th', 'August 12th', 'August 19th', 'August 26th'] }]}
            />

            <Typography variant="h5" sx={{ mt: 3 }}>Team Performance Comparison (Total Score)</Typography>
            <LineChart
                sx={{ m: 3 }}
                height={500}
                width={800}
                series={[
                    { data: [7.2, 7.8, 8.1, 8.3], label: 'DBM East Coast 1' },
                    { data: [7.1, 7.3, 7.3, 7.6], label: 'DBM East Coast 2' },
                    { data: [7.0, 7.2, 7.4, 7.5], label: 'DBM West Coast' }
                    ]}
                    xAxis={[{ scaleType: 'point', data: ['August 5th', 'August 12th', 'August 19th', 'August 26th'] }]}
            />
        </React.Fragment>
    );
}