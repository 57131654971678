import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    
} from '@mui/material';

import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [learningPath, setLearningPath] = React.useState(null);

    const { learningpath_id } = useParams();
    
    function updateLearningPathData() {
        fetch(`${brand.api_base_url}/learningpaths?learningpath_id=${learningpath_id}`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setLearningPath(data);
                console.log(data);
                document.title = `${data.name} - ${brand.name}`;
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        updateLearningPathData();
        
    },[authToken]);
    
    return (
        <React.Fragment>
            {learningPath != null && (
                <>
                    <Button href="/dashboard/learningpaths" sx={{ mb: 2 }}>Back to all Guru paths</Button>

                    <Typography variant="h4">{learningPath.name}</Typography>
                    {learningPath.steps && learningPath.steps.length > 0 && learningPath.steps.map((step, index) => (
                        <Card sx={{ display: 'flex', m: 1 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <CardMedia
                                    component="img"
                                    sx={{ height: 160, maxWidth: '160px' }}
                                    image={step.scenario.image}
                                    
                                    title=""
                                />
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography variant="h5">{step.scenario.name}</Typography>
                                    <Typography variant="body1">{step.scenario.short_description}</Typography>
                                    <Button href={`/dashboard/simulate/${step.scenario.id}`} variant="contained" size="large" sx={{ mt: 2 }} disabled={step.scenario.disabled}>Launch Simulation</Button>
                                </CardContent>
                            </Box>
                        </Card>
                    ))}
                </>
            )}
        </React.Fragment>
    );
}