import * as React from "react";
import Cookies from 'js-cookie';
import {
    createBrowserRouter,
    RouterProvider,
    Routes,
    Route,
    Navigate,
    useLocation,
  } from "react-router-dom";
import LandingPage from './LandingPage';
import Dashboard from './components/Dashboard';
import DashboardHome from "./components/dashboard/DashboardHome";
import Signup from "./pages/Signup";
import LoginPage from "./pages/Login";
import ResetPasswordPage from "./pages/ResetPassword";
import LogoutPage from "./pages/Logout";
import { Info } from "./pages/Info";
import { LanderPage } from "./pages/Lander";

import { Error404 } from "./pages/Error404";

import './App.css';
import { Stack, CircularProgress } from '@mui/material';
import DashboardLearningPaths from "./components/dashboard/LearningPaths";
import DashboardLearningPath from "./components/dashboard/LearningPath";
import DashboardLeaderBoard from "./components/dashboard/LeaderBoard";
import DashboardSimulations from "./components/dashboard/Simulations";
import DashboardTeam from "./components/dashboard/Team";
import DashboardSettings from "./components/dashboard/Settings";
import DashboardRunSimulation from "./components/dashboard/RunSimulation";
import DashboardSimulationFeedback from "./components/dashboard/SimulationFeedback";
import { DemoBrief } from "./pages/DemoBrief";
import { DemoDebrief } from "./pages/DemoDebrief";
import { DemoFeedback } from "./pages/DemoFeedback";

export const BrandContext = React.createContext(null);

function App() {
    const [brand, setBrand] = React.useState(null);
    const [authToken, setAuthToken] = React.useState('');
    const [loggedInUser, setLoggedInUser] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    

    var api_base_url;
    var bdata = {};

    React.useEffect(() => {
        // fetch the authentication token from a cookie
        const token = Cookies.get('token');
        if(token && token.length > 0) {
            setAuthToken(token);
        }
    },[]);

    React.useEffect(() => {
        if(authToken.length < 1) {
            return;
        }

        // fetch logged in user
        fetch(`${brand.api_base_url}/user`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                if(Object.keys(data).includes('email')) {
                    // success
                    setLoggedInUser(data);

                } else {
                    // failed, log out
                    setLoggedInUser({});
                    saveAuthToken('');
                }
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
        
    },[authToken]);

    React.useEffect(() => {
        if(brand && brand.has('api_base_url')) {
            api_base_url = brand.api_base_url;
        } else {
            if(window.location.hostname.indexOf('.') > 0) {
                if(window.location.hostname.indexOf('app') > -1) {
                    api_base_url = `https://${window.location.hostname.replace("app", "api")}/v1`;
                } else {
                    api_base_url = `https://api.${window.location.hostname}/v1`;
                }
            } else {
                api_base_url = 'http://localhost:8000/v1';
            }
            bdata['api_base_url'] = api_base_url;
            bdata['name'] = "GuruNow";
            setBrand(bdata);
        }
        document.title = "GuruNow";
        setLoading(false);
        
    },[]);

    function saveAuthToken(token) {
        Cookies.set('token', token, { expires: 7, secure: true });
        setAuthToken(token);
        setLoggedInUser({});
    }

    if (loading===true)
    {
        return (
            <>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: 1, height: "100vh" }}
                    >
                    <CircularProgress />
                </Stack>
            </>
        )
    } else {
        const router = createBrowserRouter([
            {
                path: "/",
                element: <LandingPage />,
            },
            {
                path: "/demobrief/:scenario_id",
                element: <DemoBrief />,
            },
            {
                path: "/demodebrief/:simulation_id",
                element: <DemoDebrief />,
            },
            {
                path: "/demofeedback/:simulation_id",
                element: <DemoFeedback />,
            },
            {
                path: "/info/:pagename",
                element: <Info />,
            },
            {
                path: "/lander/:pagename",
                element: <LanderPage />,
            },
            {
                path: "/404",
                element: <Error404 />,
            },
            {
                path: "/dashboard/",
                element: <Dashboard />,
                children: [
                    {
                        path: "home",
                        element: <DashboardHome />,
                    },
                    {
                        path: "simulations",
                        element: <DashboardSimulations />,
                    },
                    {
                        path: "learningpaths",
                        element: <DashboardLearningPaths />,
                    },
                    {
                        path: "learningpath/:learningpath_id",
                        element: <DashboardLearningPath />,
                    },
                    {
                        path: "team",
                        element: <DashboardTeam />,
                    },
                    {
                        path: "leaderboard",
                        element: <DashboardLeaderBoard />,
                    },
                    {
                        path: "settings",
                        element: <DashboardSettings />,
                    },
                    {
                        path: "simulate/:scenario_id",
                        element: <DashboardRunSimulation />,
                    },
                    {
                        path: "feedback/:simulation_id",
                        element: <DashboardSimulationFeedback />,
                    },
                ],
            },
            {
                path: "/signup",
                element: <Signup />,
            },
            {
                path: "/signup/:signupFlowName",
                element: <Signup />,
            },
            {
                path: "/login",
                element: <LoginPage />,
            },
            {
                path: "/reset_password",
                element: <ResetPasswordPage />,
            },
            {
                path: "/logout",
                element: <LogoutPage />,
            },
            ]);
    
        return (
        <React.StrictMode>
            <BrandContext.Provider value={{ brand: brand, authToken: authToken, saveAuthToken: saveAuthToken, user: loggedInUser }}>
                <RouterProvider router={router} />
            </BrandContext.Provider>
        </React.StrictMode>
        );
    }
}

export default App;
