import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,

} from '@mui/material';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import ReactTimeAgo from 'react-time-ago'
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export default function DashboardMarketing() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [simulation, setSimulation] = React.useState(null);

    const { simulation_id } = useParams();

    function setLabel(metricKey) {
        return metricKey.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())
    }

    function updateSimulationData() {
        fetch(`${brand.api_base_url}/simulation?simulation_id=${simulation_id}`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setSimulation(data);
                console.log(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    React.useEffect(() => {
        document.title = `Simulation Feedback - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        updateSimulationData();

    }, [authToken]);

    return (
        <React.Fragment>
            {(simulation == null || simulation.evaluation == null) ? (
                <>
                    <Typography variant="h4">Gathering feedback, please try again shortly.</Typography>
                    <Button href="/dashboard/simulations" sx={{ mt: 2 }}>Back to simulations</Button>
                </>
            ) : (
                <>
                    <Button href="/dashboard/simulations" sx={{ mb: 2 }}>Back to simulations</Button>

                    <Typography variant="h4">Simulation Feedback</Typography>

                    <Paper sx={{ p: 2, m: 1 }}>
                        <Stack direction="row">
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="h4">{simulation.evaluation.total_score?.title || "Total Score"}</Typography>
                                <Gauge
                                    width={300}
                                    height={300}
                                    value={simulation.evaluation.total_score?.value || simulation.evaluation.total_score}
                                    startAngle={-90}
                                    endAngle={90}
                                    sx={{
                                        [`& .${gaugeClasses.valueText}`]: {
                                            fontSize: 40,
                                            transform: 'translate(0px, 0px)',
                                        },
                                        [`& .${gaugeClasses.valueArc}`]: {
                                            fill: '#52b202',
                                        },
                                    }}
                                    text={
                                        ({ value, valueMax }) => (value / 10).toFixed(2)
                                    }
                                />
                            </Box>
                            <Box sx={{ p: 4 }}>
                                User: {simulation.user.first_name} {simulation.user.last_name} ({simulation.user.email})<br />
                                Scenario: {simulation.scenario.name}<br />
                                Grading: {simulation.evaluation.engine_name || 'Default Scoring'}<br />
                                Started at: {simulation.started_at}<br />
                                Ended at: {simulation.ended_at}<br />
                            </Box>
                        </Stack>
                    </Paper>

                    {Object.entries(simulation.evaluation).map(([metricKey, metricData]) => {


                        if (metricKey === 'total_score' || metricKey === 'engine_name') {
                            return null;
                        }

                        return (
                            <Paper sx={{ p: 2, m: 1 }} key={metricKey}>
                                <Stack direction="row">
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="h4">{metricData?.label || setLabel(metricKey)}</Typography>
                                        <Gauge
                                            width={300}
                                            height={300}
                                            value={metricData.value}
                                            startAngle={-90}
                                            endAngle={90}
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 40,
                                                    transform: 'translate(0px, 0px)',
                                                },
                                            }}
                                            text={({ value }) => (value / 10).toFixed(1)}
                                        />
                                    </Box>
                                    <Box sx={{ p: 4 }}>
                                        {metricData.positives && metricData.positives.length > 0 && (
                                            <>
                                                <Typography variant="h5">Here's what went well:</Typography>
                                                <List dense sx={{ width: '100%' }}>
                                                    {metricData.positives.map((item, index) => (
                                                        <ListItem key={`${metricKey}-positive-${index}`}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <ThumbUpAltIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                        {metricData.recommendations && metricData.recommendations.length > 0 && (
                                            <>
                                                <Typography variant="h5">
                                                    Improve your score with these recommendations:
                                                </Typography>
                                                <List dense sx={{ width: '100%' }}>
                                                    {metricData.recommendations.map((item, index) => (
                                                        <ListItem key={`${metricKey}-recommendation-${index}`}>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <TrendingUpIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                    </Box>
                                </Stack>
                            </Paper>
                        );
                    })}
                </>
            )}
        </React.Fragment>
    );
}